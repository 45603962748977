import { useMainStore } from "../store/index";

export default defineNuxtPlugin((app) => {
  const nuxtApp = useNuxtApp();
  const store = useMainStore();

  const isHMR = import.meta.hot ? true : false;
  const $config = nuxtApp.$config.public;
  const isBrowser = import.meta.browser ? true : false;

  // if (!isHMR) {
  //   return
  // }

  if (!isBrowser) {
    return
  }

  const loadBaiduMaps = () => new Promise((resolve, reject) => {
    window.initMap = () => {
      const markerClustererScript = document.createElement('script');
      markerClustererScript.src = 'https://api.map.baidu.com/library/MarkerClusterer/1.2/src/MarkerClusterer_min.js';
      markerClustererScript.onload = () => {
        const textIconOverlayScript = document.createElement('script');
        textIconOverlayScript.src = 'https://api.map.baidu.com/library/TextIconOverlay/1.2/src/TextIconOverlay_min.js';
        textIconOverlayScript.onload = () => {
          resolve(window.BMap);
        };
        textIconOverlayScript.onerror = reject;
        document.head.appendChild(textIconOverlayScript);
      };
      markerClustererScript.onerror = reject;
      document.head.appendChild(markerClustererScript);
    };
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.src = `https://api.map.baidu.com/api?v=2.0&ak=4WQ1IhfmmiNmjujo992AbxrePwPvgEdc&callback=initMap`;
    script.onerror = reject;
    document.head.appendChild(script);
  })

  app.provide('loadBaiduMaps', loadBaiduMaps);
  // return {
  //   provide: { loadBaiduMaps },
  // };
})

