import { useMainStore } from "../store/index";

export default defineNuxtPlugin((app) => {
    const store = useMainStore();
    const storeData = store.currentSite;
    const isHMR = import.meta.hot ? true : false;
    const nuxtApp = useNuxtApp();

    nuxtApp.provide("loadGTM", () => {
        if (!import.meta.browser || !store.country) {
            return;
        }

        // Regional GTM codes
        const GTMRegions = {
            africa: 'GTM-WH8HBPV',
            americas: 'GTM-NKQ4K4M',
            asia: 'GTM-NNFG8C8',
            europe: 'GTM-5B6K6RC',
            oceania: 'GTM-KJKR73N'
        };

        // Attempt to get GTM ID from storeData.settings or fallback to region-based GTM
        const gtmId = storeData?.settings?.google_tag_manager || GTMRegions[storeData?.country?.region?.toLowerCase()];

        if (!gtmId) {
            console.warn("[gtm] No GTM ID found in storeData.settings or region mapping.");
            return;
        }

        // Initialize the data layer for Google Tag Manager
        window.dataLayer = window.dataLayer || [];
        window.gtag = function () {
            window.dataLayer.push(arguments);
        };

        // Default consent mode settings
        window.gtag("consent", "default", {
            ad_storage: "denied",
            analytics_storage: "denied",
            wait_for_update: 2000,
        });
        window.gtag("set", "ads_data_redaction", true);

        // Track page views
        nuxtApp.router && nuxtApp.router.afterEach((to, from) => {
            if (window.gtag && !isHMR && to.path !== from.path) {
                window.gtag("config", gtmId, {
                    page_path: to.fullPath,
                });
            }
        });

        // Load GTM script
        (function (w, d, s, l, i) {
            w[l] = w[l] || [];
            w[l].push({
                "gtm.start": new Date().getTime(),
                event: "gtm.js",
            });
            const f = d.getElementsByTagName(s)[0];
            const j = d.createElement(s);
            const dl = l !== "dataLayer" ? "&l=" + l : "";
            j.async = true;
            j.src = "https://www.googletagmanager.com/gtm.js?id=" + i + dl;
            f.parentNode.insertBefore(j, f);
        })(window, document, "script", "dataLayer", gtmId);
    });
});
