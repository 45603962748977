
import { useMainStore } from "~/store";

export default defineNuxtRouteMiddleware((to, from) => {
  const currentSite = useMainStore().currentSite;

  if (to.path == '/int-en/news/morbidelli-gears-up-for-eicma-2024-a-revival-of-the-iconic-brand') {
    return navigateTo("/int-en/news/morbidelli-gears-up-for-eicma-2024-a-revival-of-the-iconic-brand_61", { replace: true });
  }

  if (
    currentSite &&
    currentSite.settings &&
    !currentSite.settings.test_ride_inquiry &&
    to.path.includes("test-drive")
  ) {
    throw createError({
      message: "This page could not be found.",
      statusCode: 404,
    });
  }
  if (
    currentSite &&
    currentSite.settings &&
    !currentSite.settings.become_a_partner &&
    to.path.includes("become-a-dealer")
  ) {
    throw createError({
      message: "This page could not be found.",
      statusCode: 404,
    });
  }
  if (
    currentSite &&
    currentSite.settings &&
    !currentSite.settings.become_a_partner &&
    to.path.includes('become-a-distributor')
  ) {
    throw createError({
      message: "This page could not be found.",
      statusCode: 404,
    });
  }
  if (
    currentSite &&
    currentSite.settings &&
    !currentSite.settings.work_with_us &&
    to.path.includes("work-with-us")
  ) {
    throw createError({
      message: "This page could not be found.",
      statusCode: 404,
    });
  }
});