import { useMainStore } from "../store/index";

export default defineNuxtPlugin((nuxtApp) => {
    if (import.meta.browser) {
        import('vue3-toastify').then(Vue3Toastify => {
            import('vue3-toastify/dist/index.css').then(c => {
                const store = useMainStore();
                const { toast, updateGlobalOptions } = Vue3Toastify

                nuxtApp.vueApp.use(Vue3Toastify);

                updateGlobalOptions({
                    autoClose: 1000,
                    theme: "dark",
                    rtl: store.isRtl,
                    position: toast.POSITION.BOTTOM_CENTER,
                    hideProgressBar: true,
                });

                nuxtApp.provide('toast', toast);
            })
        })
    }
});