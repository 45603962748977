import { useMainStore } from "../store/index";

export default defineNuxtPlugin((app) => {
  const store = useMainStore();

  let replicaLight = "Replica-Light";
  let replicaRegular = "Replica-Regular";
  let replicaBold = "Replica-Bold";
  let replicaPro = "Replica-Pro";

  if (store.locale == "sl") {
    replicaLight = "Manrope-Light";
    replicaRegular = "Manrope-Regular";
    replicaBold = "Manrope-Bold";
    replicaPro = "Manrope-Pro";

  }

  if (import.meta.browser) {
    if (store.locale == "sl") {
      document.body.classList.add('manrope')
    } else {
      document.body.classList.add('replica')
    }

    document.body.style.setProperty("--replica-light", replicaLight);
    document.body.style.setProperty("--replica-regular", replicaRegular);
    document.body.style.setProperty("--replica-bold", replicaBold);
    document.body.style.setProperty("--replica-pro", replicaPro);
  }
});
