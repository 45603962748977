import { useMainStore } from "~/store";

export default defineNuxtPlugin((app) => {
  const store = useMainStore();
  if (!import.meta.browser) {
    return false;
  }

  if (
    store.currentSite &&
    store.currentSite.settings &&
    store.currentSite.settings.microsoft_clarity &&
    store.currentSite.settings.microsoft_clarity.integrate
  ) {
    const key = store.currentSite.settings.microsoft_clarity.key;
    !(function (c, l, a, r, i, t, y) {
      c[a] =
        c[a] ||
        function () {
          (c[a].q = c[a].q || []).push(arguments);
        };

      t = l.createElement(r);
      t.async = 1;
      t.src = "https://www.clarity.ms/tag/" + i;

      y = l.getElementsByTagName(r)[0];
      y.parentNode.insertBefore(t, y);
    })(window, document, "clarity", "script", key);
  }
});
